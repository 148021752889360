// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jogo-de-tarot-js": () => import("./../src/pages/jogo-de-tarot.js" /* webpackChunkName: "component---src-pages-jogo-de-tarot-js" */),
  "component---src-pages-mapa-astral-create-js": () => import("./../src/pages/mapa/astral/create.js" /* webpackChunkName: "component---src-pages-mapa-astral-create-js" */),
  "component---src-pages-numerologia-js": () => import("./../src/pages/numerologia.js" /* webpackChunkName: "component---src-pages-numerologia-js" */),
  "component---src-pages-oraculo-baralho-cigano-js": () => import("./../src/pages/oraculo/baralho-cigano.js" /* webpackChunkName: "component---src-pages-oraculo-baralho-cigano-js" */),
  "component---src-pages-oraculo-cristais-js": () => import("./../src/pages/oraculo/cristais.js" /* webpackChunkName: "component---src-pages-oraculo-cristais-js" */),
  "component---src-pages-oraculo-runas-js": () => import("./../src/pages/oraculo/runas.js" /* webpackChunkName: "component---src-pages-oraculo-runas-js" */),
  "component---src-pages-oraculo-tarot-da-sorte-js": () => import("./../src/pages/oraculo/tarot-da-sorte.js" /* webpackChunkName: "component---src-pages-oraculo-tarot-da-sorte-js" */),
  "component---src-pages-oraculo-tarot-do-amor-js": () => import("./../src/pages/oraculo/tarot-do-amor.js" /* webpackChunkName: "component---src-pages-oraculo-tarot-do-amor-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-test-js": () => import("./../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-anjos-list-js": () => import("./../src/templates/anjos-list.js" /* webpackChunkName: "component---src-templates-anjos-list-js" */),
  "component---src-templates-anjos-single-js": () => import("./../src/templates/anjos-single.js" /* webpackChunkName: "component---src-templates-anjos-single-js" */),
  "component---src-templates-compatibilidade-amorosa-list-js": () => import("./../src/templates/compatibilidade-amorosa-list.js" /* webpackChunkName: "component---src-templates-compatibilidade-amorosa-list-js" */),
  "component---src-templates-compatibilidade-amorosa-single-js": () => import("./../src/templates/compatibilidade-amorosa-single.js" /* webpackChunkName: "component---src-templates-compatibilidade-amorosa-single-js" */),
  "component---src-templates-horoscopo-list-js": () => import("./../src/templates/horoscopo-list.js" /* webpackChunkName: "component---src-templates-horoscopo-list-js" */),
  "component---src-templates-horoscopo-single-js": () => import("./../src/templates/horoscopo-single.js" /* webpackChunkName: "component---src-templates-horoscopo-single-js" */)
}

